import { JoyStep } from '@templates/Onboarding'
import { InfoCircleTwoTone } from '@ant-design/icons'
import {
  ACK_WITH_RISK_MAPS,
  DANGEROUS_REGISTRY,
  POSITIONS_WITHOUT_RISK_MAPS,
  RISK_MANAGEMENT_PLAN,
  RISK_MANAGEMENT_SYSTEM,
  SIZ,
} from '@const/reportNames'

export const REPORTS_ONBOARDING_TITLE =
  'Расскажем, как создавать отчёты по картам рисков'

export const REPORTS_ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#reportsContainer',
    title: 'Расскажем, как создавать отчёты по картам рисков',
    content: 'Чтобы создать отчет, нажмите на него',
  },
  {
    target: '#dangers',
    title: DANGEROUS_REGISTRY,
    content: (
      <div>
        <div>
          В реестре выводится статистика по опасностям, входящим в выбранные
          должности.
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> Выводятся данные
          только по картам рисков в статусе: Утверждён, Изменён, Продлён.
        </div>
      </div>
    ),
  },
  {
    target: '#risk-plan',
    title: RISK_MANAGEMENT_PLAN,
    content: (
      <div>
        <div>
          В отчёте выводятся меры по опасностям, входящим в карты рисков
          выбранных должностей.
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> Выводятся данные
          только по картам рисков в статусе: Утверждён, Изменён, Продлён.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> Выводятся меры только
          по опасностям со значимостью риска Высокий или Средний.
        </div>
      </div>
    ),
  },
  {
    target: '#risk-maps-reviews',
    title: ACK_WITH_RISK_MAPS,
    content: (
      <div>
        <div>
          В отчёте выводится статистика по ознакомлению с текущими версиями карт
          рисков.
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} />
          Выводятся данные только по картам рисков в статусе: Утверждён,
          Изменён, Продлён.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} />
          Если вы пересмотрели карту рисков со статусом "Изменён" (заменили
          файл), то нужно заново отправлять карту на ознакомление.
        </div>
      </div>
    ),
  },
  {
    target: '#positions-without-risk-maps',
    title: POSITIONS_WITHOUT_RISK_MAPS,
    content: (
      <div>
        <div>
          В отчёте выводится список должностей, по которым не составлена карта
          рисков в статусе Утверждён, Изменён, Продлён.
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
      </div>
    ),
  },
  {
    target: '#risk-management-system',
    title: RISK_MANAGEMENT_SYSTEM,
    content: (
      <div>
        <div>
          Скачивается документ в формате doc с положением об управлении
          профессиональными рисками.
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
      </div>
    ),
  },
  {
    target: '#siz',
    title: SIZ,
    content: (
      <div>
        <div>
          Отчёт по средства индивидуальной защиты, которые указаны в картах
          рисков.
        </div>
        <div>
          <InfoCircleTwoTone style={{ color: 'white' }} /> После перехода к
          отчёту вы сможете выбрать карты рисков, по которым сделать отчёт.
        </div>
      </div>
    ),
  },
]
