import * as React from 'react'
import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  useCreateEmployeeEducationMutation,
  useUpdateEmployeeEducationMutation,
} from '@services/employeeEducation'
import {
  CommonEmployeeEducationBody,
  EmployeeEducationDetailed,
} from '@models/employeeEducation'
import AddNewItemModal from '@templates/AddNewItemModal'
import { Option } from '@organisms/Select'
import useSetFilialInfoFields from '@modals/EmployeeEducationModal/useSetFilialInfoFields'
import CommissionMemberMultilineFields from '@templates/CommissionMemberMultilineFields'
import { WhomAssignee } from '@enums/WhomAssignee'
import { DescriptionWrapper } from '@modals/EmployeeEducationModal/styled'
import OnboardingCodes from '@enums/OnboardingCodes'
import { getMultilineValue } from '@utils/form'
import useEmployeeEducationFields from './useEmployeeEducationFields'
import getDefaultValues from './getDefaultValues'
import Footer from './Footer'
import RepeatEducationField from './RepeatEducationField'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

export type FormValues = {
  title: string
  whomAssign: Option
  course: Option
  type?: Option
  company?: Option
  filial?: Option
  division?: Option
  position?: Option
  checkingResult?: string
  commissionHead?: Option
  deadlineAt?: string
  localActName?: string
  protocolDate?: string
  protocolNumber?: string
  trainingReason?: string
  conductsBriefing?: Option
  workPlace?: string
  employerInn?: string
  educationOrganization?: string
  educationOrganizationInn?: string
  repeatEducationAtMonth?: string
  employeesList?: string
  commissionMembers: Option[]
  employees: Option[]
} & Record<string, any>

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  employeeEducation?: EmployeeEducationDetailed
}

const EmployeeEducationModal: FC<Props> = ({
  isEdit = false,
  handleCloseModal,
  employeeEducation,
}) => {
  const { state } = useLocation()
  const [create, createData] = useCreateEmployeeEducationMutation()
  const [update, updateData] = useUpdateEmployeeEducationMutation()
  const additionalProps = isEdit ? updateData : createData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(state || employeeEducation),
  })
  const fields = useEmployeeEducationFields(methods, isEdit, employeeEducation)
  useSetFilialInfoFields(methods, isEdit)

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonEmployeeEducationBody = {
      whomAssign: form.whomAssign?.value as string,
      checkingResult: form.checkingResult || null,
      commissionHead: form.commissionHead?.value || null,
      commissionMembers: getMultilineValue(form.commissionMembers),
      company: form.company?.value,
      companyBranch: form.filial?.value,
      conductsBriefing: form.conductsBriefing?.value || null,
      course: form.course?.value,
      deadlineAt: form.deadlineAt || null,
      employees:
        form.whomAssign?.value === WhomAssignee.EMPLOYEE &&
        form.employees?.length > 0
          ? form.employees?.map((item) => item.value)
          : undefined,
      jobPosition: form.position?.value,
      localActName: form.localActName || null,
      protocolDate: form.protocolDate || null,
      protocolNumber: form.protocolNumber || null,
      subdivision: form.division?.value,
      title: form.title,
      trainingReason: form.trainingReason || null,
      type: (form.type?.value as string) || null,
      workPlace: form.workPlace || null,
      employerInn: form.employerInn || null,
      educationOrganization: form.educationOrganization || null,
      educationOrganizationInn: form.educationOrganizationInn || null,
      repeatEducationAtMonth: form.repeatEducationAtMonth
        ? +form.repeatEducationAtMonth
        : null,
      employeesList: form.employeesList,
    }

    if (isEdit) {
      if (employeeEducation) {
        update({
          ...commonForm,
          id: employeeEducation.id,
        })
      }
    } else {
      create(commonForm)
    }
  }

  return (
    <AddNewItemModal
      {...additionalProps}
      titlePostfix="обучение"
      fields={fields}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      withSaveAndAddMore
      contentBeforeFields={
        <DescriptionWrapper>
          Внимание! Для создания реестра минтруда должны быть заполнены
          следующие данные: СНИЛС сотрудника, Место работы, ИНН организации
          работодателя, Обучающая организация, ИНН обучающей организации
        </DescriptionWrapper>
      }
      contentAfterFields={
        <>
          <CommissionMemberMultilineFields />
          <RepeatEducationField />
        </>
      }
      handleCloseModal={handleCloseModal}
      isEdit={isEdit}
      CustomFooter={isEdit ? undefined : Footer}
      onboarding={{
        steps: ONBOARDING_STEPS,
        firstStepTitle: ONBOARDING_TITLE,
        code: OnboardingCodes.education_create,
        withScroll: true,
        withFeedback: true,
      }}
    />
  )
}

export default EmployeeEducationModal
