import styled from '@emotion/styled'

export const Wrapper = styled.div`
  padding: 20px 26px;
  border-radius: 32px;
  background-color: #c4c4c4;
  display: flex;
  gap: 8px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  align-items: center;
`
