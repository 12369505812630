import * as React from 'react'
import { FC } from 'react'
import EmployeesCountField from '@templates/EmployeesCountField'
import { RiskMapVersionOption } from '@models/riskMapVersions'
import { RiskMapDetailed } from '@models/riskMap'
import { Wrapper } from './styled'

type Props = {
  item: RiskMapVersionOption
  riskMap: RiskMapDetailed
  index: number
}

const MainInfoItem: FC<Props> = ({ item, riskMap, index }) => {
  const { employeeCount, reviewedEmployeeCount, notReviewedEmployeeCount } =
    item
  const iri = item['@id']

  return (
    <Wrapper id={`risk-map-main-${index}`}>
      <EmployeesCountField
        label="Сотрудники, которым доступна карта рисков"
        countEmployees={employeeCount}
        isAccordionField
        company={{
          label: riskMap.jobPosition.subdivision.companyBranch.company.title,
          value: riskMap.jobPosition.subdivision.companyBranch.company['@id'],
        }}
        filial={{
          label: riskMap.jobPosition.subdivision.companyBranch.title,
          value: riskMap.jobPosition.subdivision.companyBranch['@id'],
        }}
        division={{
          label: riskMap.jobPosition.subdivision.title,
          value: riskMap.jobPosition.subdivision['@id'],
        }}
        position={{
          label: riskMap.jobPosition.title,
          value: riskMap.jobPosition['@id'],
        }}
      />
      <EmployeesCountField
        label="Сотрудники, которые ознакомились с картой рисков"
        countEmployees={reviewedEmployeeCount}
        isAccordionField
        reviewedRiskMapRevision={iri}
      />
      <EmployeesCountField
        label="Сотрудники, которые не ознакомились с картой рисков"
        countEmployees={notReviewedEmployeeCount}
        isAccordionField
        notReviewedRiskMapRevision={iri}
      />
    </Wrapper>
  )
}

export default MainInfoItem
