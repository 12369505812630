import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import StoreTags from '@enums/StoreTags'
import {
  Course,
  CourseDetailed,
  CourseQueryParams,
  CourseServerResponse,
  CreateCourseBody,
  UpdateCourseBody,
} from '@models/course'
import { Certificate } from '@models/certificates'
import { ItemsFromResponse } from '@models/common'
import { createDownloadExcelReportQuery } from '@utils/files'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/courses'

const getCourses = (builder: EndpointBuilder<any, any, any>) =>
  builder.query<{ items: Course[]; length: number }, CourseQueryParams>({
    providesTags: [StoreTags.COURSES],
    query: (params) => ({
      url: BASE_URL,
      params,
    }),
    transformResponse: (response: ItemsFromResponse<CourseServerResponse>) => ({
      items: response[HYDRA_MEMBER].map((item) => ({
        ...item,
        value: item['@id'],
        label: item.title,
      })),
      length: response[HYDRA_TOTAL_ITEMS],
    }),
  })

export const coursesApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCourses: getCourses(builder),
    getAllCourses: getCourses(builder),
    getCourseById: builder.query<CourseDetailed, number | string>({
      providesTags: [StoreTags.COURSE],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    postCourse: builder.mutation<CourseDetailed, CreateCourseBody>({
      invalidatesTags: (result) => (result ? [StoreTags.COURSES] : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    putCourse: builder.mutation<CourseDetailed, UpdateCourseBody>({
      invalidatesTags: (result) =>
        result ? [StoreTags.COURSES, StoreTags.COURSE] : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getCertificate: builder.query<
      Certificate,
      {
        courseId: string | number
        educationId: string | number
        userId: string | number
      }
    >({
      query: ({ courseId, educationId, userId }) => ({
        url: `${BASE_URL}/${courseId}/certificate/listener/${userId}/${educationId}`,
      }),
    }),
    getSafeProgramReport: builder.query<void, string | number>({
      query: (id) =>
        createDownloadExcelReportQuery(
          `${BASE_URL}/${id}/safe_program_report`,
          'Программа обучения для работающих с вредными условиями труда.xls'
        )({}),
    }),
  }),
})

export const {
  useGetCoursesQuery,
  useGetCourseByIdQuery,
  usePostCourseMutation,
  usePutCourseMutation,
  useLazyGetCertificateQuery,
  useLazyGetCourseByIdQuery,
  useLazyGetSafeProgramReportQuery,
} = coursesApi
