import { compact } from 'lodash'
import { UseFormReturn } from 'react-hook-form'
import { checkboxField, fileField } from '@templates/AddNewItemModal/templates'
import { Fields } from '@templates/AddNewItemModal/Content'
import useJobFields from '@hooks/useJobFields'

export const AUTO_NAME = 'createDangers'

const useRiskMapFromFileFields = (methods: UseFormReturn): Fields => {
  const { company, filial } = useJobFields(methods)

  return [
    {
      fields: compact([
        company?.({
          isRequired: true,
        }),
        filial?.({
          isRequired: true,
        }),
        fileField({
          name: 'file',
          label: 'Файл',
          formats: ['xlsx'],
        }),
      ]),
    },
    {
      fields: compact([
        checkboxField({
          name: AUTO_NAME,
          label: 'Автоматически добавить подходящие опасности к картам',
          promptText:
            'Если к указанной должности будут найдены подходящие опасности, они будут добавлены вместе с мерами и средствами индвидуальной защиты.',
          widthMultiplier: 3,
        }),
      ]),
    },
  ]
}

export default useRiskMapFromFileFields
