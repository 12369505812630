import { JoyStep } from '@templates/Onboarding'
import {
  ANTI_FIRE_REPORT_NAME,
  ELECTRO_SAFE_REPORT_NAME,
  EMPLOYEES_REPORT_NAME,
  INITIAL_BRIEFING_REPORT_NAME,
  OTHER_BRIEFING_REPORT_NAME,
  POSITIONS_REPORT_NAME,
} from '@const/reportNames'

export const REPORTS_ONBOARDING_TITLE =
  'Расскажем, как создавать отчёты по обучениям'

export const REPORTS_ONBOARDING_STEPS: JoyStep[] = [
  {
    target: '#reportsContainer',
    title: 'Расскажем, как создавать отчёты по обучению',
    content: 'Чтобы создать отчет, нажмите на него',
  },
  {
    target: '#employees',
    title: `Отчёт “${EMPLOYEES_REPORT_NAME}”`,
    content: (
      <div>
        <div>В отчёте выводятся данные о прохождении курсов.</div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
      </div>
    ),
  },
  {
    target: '#initialBriefing',
    title: INITIAL_BRIEFING_REPORT_NAME,
    content: (
      <div>
        <div>
          В журнал попадают все <b>завершённые</b> обучения сотрудников с типом
          обучения “Вводный инструктаж”.
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
      </div>
    ),
  },
  {
    target: '#otherBriefing',
    title: OTHER_BRIEFING_REPORT_NAME,
    content: (
      <div>
        <div>
          В журнал попадают все <b>завершённые</b> обучения сотрудников с типами
          обучения:
          <ul>
            <li>Первичный инструктаж</li>
            <li>Повторный инструктаж</li>
            <li>Внеплановый инструктаж</li>
            <li>Целевой инструктаж</li>
          </ul>
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
      </div>
    ),
  },
  {
    target: '#fireSafety',
    title: ANTI_FIRE_REPORT_NAME,
    content: (
      <div>
        <div>
          В журнал попадают все <b>завершённые</b> обучения сотрудников с
          курсами из категории “Пожарная безопасность”
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
      </div>
    ),
  },
  {
    target: '#electricalSafety',
    title: ELECTRO_SAFE_REPORT_NAME,
    content: (
      <div>
        <div>
          В журнал попадают все <b>завершённые</b> обучения сотрудников с курсом
          <div>
            “Программа присвоения 1 группы по электробезопасности для
            неэлектротехнического персонала”
          </div>
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
      </div>
    ),
  },
  {
    target: '#positions',
    title: `Отчёт “${POSITIONS_REPORT_NAME}”`,
    content: (
      <div>
        <div>
          В отчёт выводятся данные о сотрудниках, которые были указаны в
          комиссии по обучениям.
        </div>
        <div>
          При переходе к отчёту вы сможете дополнительно отфильтровать данные.
        </div>
      </div>
    ),
  },
]
