import { UseFormReturn } from 'react-hook-form'
import { compact } from 'lodash'
import useCourseCategories from '@hooks/useCourseCategories'
import COURSE_LEARN_TYPES from '@const/courseSubTypes'
import useJobFields from '@hooks/useJobFields'
import COURSE_TYPES from '@const/courseTypes'
import { Fields } from '@templates/AddNewItemModal/Content'
import {
  checkboxField,
  linkField,
  numberField,
  selectField,
  textField,
} from '@templates/AddNewItemModal/templates'

const useCoursesFields = (methods: UseFormReturn): Fields => {
  const { company, filial } = useJobFields(methods)
  const { data, isLoading: courseCategoriesIsLoading } = useCourseCategories()
  const { items: courseCategories = [] } = data || {}

  return [
    {
      fields: [
        textField({
          name: 'title',
          label: 'Название курса',
          isRequired: true,
          placeholder: 'Название',
        }),
        numberField({
          name: 'hoursCount',
          label: 'Количество часов',
          placeholder: 'Количество',
          isRequired: true,
        }),
        linkField({
          name: 'descriptionLink',
          label: 'Ссылка на описание',
        }),
      ],
    },
    {
      fields: [
        selectField({
          name: 'courseCategory',
          label: 'Категория курса',
          isRequired: true,
          options: courseCategories,
          isLoading: courseCategoriesIsLoading,
          dropdownWidth: 350,
        }),
        selectField({
          name: 'type',
          label: 'Тип курса',
          isRequired: true,
          options: COURSE_TYPES,
          dropdownWidth: 350,
          withoutFetchOptions: true,
        }),
        selectField({
          name: 'subType',
          label: 'Тип обучения',
          isRequired: true,
          options: COURSE_LEARN_TYPES,
          withoutFetchOptions: true,
        }),
      ],
    },
    {
      fields: compact([
        company?.(),
        filial?.(),
        textField({
          name: 'ministryLaborId',
          label: 'ID для МинТруда',
          placeholder: 'ID для МинТруда',
        }),
      ]),
    },
    {
      fields: [
        checkboxField({
          name: 'free',
          label: 'Бесплатный',
        }),
        checkboxField({
          name: 'safeProgram',
          label: 'Программа обучения для работающих с вредными условиями труда',
          widthMultiplier: 2,
        }),
      ],
    },
    {
      fields: [
        checkboxField({
          name: 'electricalSafetyFirstGroup',
          label: 'Обучение по ЭБ 1 группа',
        }),
      ],
    },
  ]
}

export default useCoursesFields
