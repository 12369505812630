import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '@hooks/useAuth'
import { useGetTextSettingsByCodeQuery } from '@services/settings'
import { TextSettingsCodes } from '@models/settings'
import { Routes } from '@enums/Routes'
import Link from '@organisms/Link'
import { StyledLink } from './styled'

type Props = {
  text: string
  isLogout?: boolean
  route?: Routes
  handleItemClick?: () => void
  isSiteLink?: boolean
  fontSize?: number
}

const LinkItem: FC<Props> = ({
  text,
  isLogout,
  route,
  handleItemClick,
  isSiteLink,
  fontSize,
}) => {
  const { data: siteLink } = useGetTextSettingsByCodeQuery(
    TextSettingsCodes.SITE_LINK
  )

  const navigate = useNavigate()
  const { logout } = useAuth()

  const handleClick = () => {
    handleItemClick?.()

    if (isSiteLink) {
      window.open(siteLink?.text, '_blank')
    }

    if (isLogout) {
      logout()
      return
    }

    if (route) {
      navigate(route)
    }
  }

  return (
    <StyledLink key={text}>
      <Link onClick={handleClick} text={text} fontSize={fontSize} />
    </StyledLink>
  )
}

export type { Props as LinkItemProps }
export default LinkItem
