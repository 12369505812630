import { useState } from 'react'
import { useLazyGetLoginLinksQuery } from '@services/employees'
import { ReportsProp } from '@templates/ListPage/Header/ReportsButton'
import {
  BODY_CHECK_REPORT_NAME,
  LINKS_FOR_PASSWORDS_NAME,
} from '@const/reportNames'
import { useLazyGetBodyCheckReportQuery } from '@services/users'
import useFormProcessor from '@hooks/useFormProcessor'
import { Option } from '@organisms/Select'
import { CompanyDetailed } from '@models/company'
import { DivisionDetailed } from '@models/division'
import { FilialDetailed } from '@models/filial'
import { PositionDetailed } from '@models/position'
import { EmployeesReports } from '@pages/Employees/enums'
import useEmployeesFiltersModalFields from './useEmployeesFiltersModalFields'

type FormValues = {
  company: Option & CompanyDetailed
  division: Option & DivisionDetailed
  filial: Option & FilialDetailed
  position: Option & PositionDetailed
  nextBodyCheckDate?: string
}

type FormFilters = {
  'nextBodyCheckDate[before]': string | undefined
  'companyBranch.company': string | undefined
  companyBranch: string | undefined
  'subdivisionJobPositionRels.subdivision': string | undefined
  'subdivisionJobPositionRels.jobPosition': string | undefined
}

const useEmployeesReports = (): ReportsProp => {
  const [selectedReport, setSelectedReport] = useState<
    EmployeesReports | undefined
  >()

  const methods = useFormProcessor()
  const filterModalFields = useEmployeesFiltersModalFields(
    methods,
    selectedReport
  )
  const [
    getBodyCheckReportQuery,
    { isLoading: getBodyCheckReportQueryIsLoading },
  ] = useLazyGetBodyCheckReportQuery()

  const [
    getLoginLinksReportQuery,
    { isLoading: getLoginLinksReportQueryIsLoading },
  ] = useLazyGetLoginLinksQuery()

  const transformFormValues = (form: FormValues): FormFilters => {
    return {
      'nextBodyCheckDate[before]': form.nextBodyCheckDate,
      'companyBranch.company': form.company?.['@id'],
      companyBranch: form.filial?.['@id'],
      'subdivisionJobPositionRels.subdivision': form.division?.['@id'],
      'subdivisionJobPositionRels.jobPosition': form.position?.['@id'],
    }
  }

  return {
    transformFormValues,
    onSelectReport: setSelectedReport,
    methods,
    items: [
      {
        title: BODY_CHECK_REPORT_NAME,
        isLoading: getBodyCheckReportQueryIsLoading,
        onClick: getBodyCheckReportQuery,
        id: 'bodyCheck',
      },
      {
        title: LINKS_FOR_PASSWORDS_NAME,
        isLoading: getLoginLinksReportQueryIsLoading,
        onClick: getLoginLinksReportQuery,
        id: 'links',
      },
    ],
    filterModalFields,
  }
}

export default useEmployeesReports
