import { FC } from 'react'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import useMouseOverOut from '@hooks/useMouseOverOut'
import Link from '@organisms/Link'
import useMe from '@hooks/useMe'
import { Wrapper } from './styled'
import { RouteConfig } from 'src/routes'

const MenuItem: FC<RouteConfig> = ({
  ActiveIcon,
  InactiveIcon,
  text,
  getText,
  route,
  onItemClick,
  routesForMatch,
}) => {
  const me = useMe()
  const [hovered, , handleMouseEnter, handleMouseLeave] = useMouseOverOut()
  const navigate = useNavigate()
  const match = useMatch(route)
  const { pathname } = useLocation()

  const isMatch =
    !!match ||
    routesForMatch?.some((route) => route.includes(pathname.slice(0, 4)))

  const handleClick = () => {
    navigate(route)
    onItemClick?.(route)
  }

  return (
    <Wrapper
      key={route}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        text={(getText?.(me) || text)!}
        icon={{
          ActiveIcon: ActiveIcon!,
          InactiveIcon: InactiveIcon!,
        }}
        onClick={handleClick}
        isActive={isMatch || hovered}
      />
    </Wrapper>
  )
}

export default MenuItem
