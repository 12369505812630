import { FC } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import AddNewItemModal from '@templates/AddNewItemModal'
import ContentBlock from '@organisms/ContentBlock'
import { useUpdateCompanyMutation } from '@services/companies'
import { CompanyDetailed, UpdateCompanyBody } from '@models/company'
import getDefaultValues from '@modals/CompanyModal/getDefaultValues'
import { getMultilineValue } from '@utils/form'
import { File } from '@models/file'
import useCompanyFields from './useCompanyFields'

export type FormValues = {
  logo: File
  title: string
  fullTitle: string
}

type Props = {
  companyData: CompanyDetailed | undefined
}

const EditCompanyBlock: FC<Props> = ({ companyData }) => {
  const fields = useCompanyFields()

  const [update, updateData] = useUpdateCompanyMutation()

  const methods = useFormProcessor({
    apiErrors: updateData.error,
    defaultValues: getDefaultValues(companyData),
  })

  const handleSubmitForm = (form: FormValues) => {
    if (!companyData) return

    const commonForm: UpdateCompanyBody = {
      id: companyData.id,
      title: form.title || companyData.title,
      fullTitle: form.fullTitle || companyData.fullTitle,
      curators: getMultilineValue(companyData.curators),
      subscriptionsCount: companyData.subscriptionsCount
        ? +companyData.subscriptionsCount
        : undefined,
      logo: form.logo?.['@id'],
      forcePasswordChange: companyData.forcePasswordChange,
    }

    void update(commonForm)
  }

  return (
    <ContentBlock>
      <AddNewItemModal
        {...updateData}
        onSubmitForm={handleSubmitForm}
        methods={methods}
        fields={fields}
        isEdit
        customFullTitle=""
        successContentAddTitle=""
        successContentEditTitle=""
        withNotificationBanner
        withoutReset
        minHeightVh={15}
      />
    </ContentBlock>
  )
}

export default EditCompanyBlock
