import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  RiskMapJobPosition,
  RiskMapJobPositionOption,
} from '@models/riskMapJobPosition'
import emptySplitApi from '../api'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'

const BASE_URL = '/api/risk_map_job_positions'

export const RiskMapJobPositionsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRiskMapJobPositions: builder.query<
      { items: RiskMapJobPositionOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<RiskMapJobPosition>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.title,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.DANGER_EVENTS],
    }),
  }),
})

export const { useGetRiskMapJobPositionsQuery } = RiskMapJobPositionsApi
