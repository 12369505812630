import { FC } from 'react'
import { Scorm } from '@models/scorm'
import { ListItemProps } from '@templates/List'
import Accordion, { AccordionHeaderProps } from '@organisms/Accordion'
import ThemeSmiles from '@templates/ThemeSmiles'
import Body from './Body'
import { HeaderWrapper, StyledTitle } from './styled'

const Theme: FC<ListItemProps<Scorm>> = ({
  isLoading,
  item: { title, id, badVotes, excellentVotes, goodVotes },
  opened,
  handleClickOnItem,
}) => {
  const header: FC<AccordionHeaderProps> = ({ hovered }) => (
    <HeaderWrapper>
      <StyledTitle hovered={hovered}>{title}</StyledTitle>
      <ThemeSmiles
        sadDescription={badVotes}
        happyDescription={goodVotes}
        lovelyDescription={excellentVotes}
      />
    </HeaderWrapper>
  )

  return (
    <Accordion
      handleClickOnHeader={handleClickOnItem}
      expanded={opened}
      initLoading={isLoading}
      Header={header}
      Body={({ onChangeSize, toggleLoading }) => (
        <Body
          id={id}
          onChangeSize={onChangeSize}
          toggleLoading={toggleLoading}
        />
      )}
      key={id}
    />
  )
}

export default Theme
