import { FC } from 'react'
import Title from '@organisms/Title'
import FieldComponent from '@templates/Field'
import { Field } from '../index'
import { LineWrapper, Wrapper } from './styled'

type Props = {
  fields: Field[]
  lineTitle?: string
  id?: string
}

const LineFields: FC<Props> = ({ fields, lineTitle, id }) => (
  <div id={id}>
    {lineTitle && (
      <Wrapper>
        <Title>{lineTitle}</Title>
      </Wrapper>
    )}
    <LineWrapper>
      {fields.map((item) => (
        <FieldComponent key={item.name} {...item} fixedLabelHeight />
      ))}
    </LineWrapper>
  </div>
)

export default LineFields
