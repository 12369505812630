import { FC } from 'react'
import { animated, useSpring } from 'react-spring'
import { DEFAULT_ANIMATION_DURATION } from '@const/animation'
import LinkItem from '@templates/LinkItem'
import { FOOTER_LINKS_CONFIG } from './config'

type Props = {
  visible: boolean
  handleItemClick?: () => void
}

const FooterLinks: FC<Props> = ({ visible, handleItemClick }) => {
  const { x } = useSpring({
    from: { x: 0 },
    x: visible ? 1 : 0,
    config: { duration: DEFAULT_ANIMATION_DURATION },
  })

  return (
    <animated.div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        opacity: x.to({
          range: [0, 1],
          output: [0, 1],
        }),
      }}
    >
      {FOOTER_LINKS_CONFIG.map((item) => (
        <LinkItem
          {...item}
          key={item.text}
          handleItemClick={handleItemClick}
          fontSize={14}
        />
      ))}
    </animated.div>
  )
}

export default FooterLinks
