import { object, string } from 'yup'

export const PHONE_VALIDATION_RULE = {
  pattern: {
    value: /^(\+7)?[\s-]?\(?9\d{2}\)?[\s-]?\d{3}(?:[\s-]?\d{2}){2}$/g,
    message: 'Некорректный номер',
  },
}

export const REQUIRED_VALIDATION_RULE = {
  required: {
    value: true,
    message: 'Это обязательное поле',
  },
}

const MAX_LENGTH = 180
export const MAX_LENGTH_RULE = {
  maxLength: {
    value: MAX_LENGTH,
    message: `Максимальная длинна поля - ${MAX_LENGTH} символов`,
  },
}

export const EMAIL_VALIDATION_RULE = {
  pattern: {
    value: /\S+@\S+\.\S{2,}/,
    message: 'Некорректный email',
  },
}

export const getPasswordValidationSchema = (passwordFieldName: string) =>
  object().shape({
    [passwordFieldName]: string()
      .required('Это обязательное поле')
      .min(8, 'Пароль должен содержать не менее 8 символов')
      .test(
        'passwordRequirements',
        'Пароль должен содержать хотя бы 1 цифру',
        (value) => /\d/.test(value)
      )
      .test(
        'passwordRequirements',
        'Пароль должен содержать хотя бы строчную латинскую букву',
        (value) => /[a-z]/.test(value)
      )
      .test(
        'passwordRequirements',
        'Пароль должен содержать хотя бы заглавную латинскую букву',
        (value) => /[A-Z]/.test(value)
      )
      .test(
        'passwordRequirements',
        'Пароль должен содержать хотя бы 1 спец символ ( ! # $ % & * + , . / : ; < > ? @ { } )',
        (value) => /[!#$%&*+,./:;<>?@{}]+/.test(value)
      ),
  })
