import { FC, useMemo } from 'react'
import useMe from '@hooks/useMe'
import MenuItem from './MenuItem'
import { Wrapper } from './styled'
import MenuAccordion from './MenuAccordion'
import { EDUCATION_ROUTES, ORGANISATION_ROUTES } from './config'
import ROUTES_CONFIG, { RouteConfig } from 'src/routes'

const getOrganisationRoutes = (items: RouteConfig[]) =>
  items.filter((route) => ORGANISATION_ROUTES.has(route.route))

const getEducationRoutes = (items: RouteConfig[]) =>
  items.filter((route) => EDUCATION_ROUTES.has(route.route))

const getAnotherRoutes = (items: RouteConfig[]) =>
  items.filter(
    (route) =>
      !ORGANISATION_ROUTES.has(route.route) &&
      !EDUCATION_ROUTES.has(route.route)
  )

type Props = {
  onItemClick?: (route: string) => void
  smallMenu?: boolean
}

const MainMenu: FC<Props> = ({ onItemClick, smallMenu = false }) => {
  const { roles: myRoles = [] } = useMe()

  const { organisationRoutes, educationRoutes, anotherRoutes, enabledItems } =
    useMemo(() => {
      const enabledItems = ROUTES_CONFIG.filter(
        ({ roles, text, ActiveIcon, InactiveIcon }) => {
          const enabled = roles
            ? myRoles.some((myRole) => roles.has(myRole))
            : true

          return !(!enabled || !text || !ActiveIcon || !InactiveIcon)
        }
      )

      const organisationRoutes = getOrganisationRoutes(enabledItems)
      const educationRoutes = getEducationRoutes(enabledItems)
      const anotherRoutes = getAnotherRoutes(enabledItems)

      return {
        organisationRoutes,
        educationRoutes,
        anotherRoutes,
        enabledItems,
      }
    }, [myRoles])

  if (smallMenu) {
    return (
      <Wrapper>
        {enabledItems.map((item) => (
          <MenuItem {...item} key={item.route} onItemClick={onItemClick} />
        ))}
      </Wrapper>
    )
  }

  return (
    <>
      {organisationRoutes.length > 0 && (
        <MenuAccordion title="Организация">
          <Wrapper>
            {organisationRoutes.map((item) => (
              <MenuItem {...item} key={item.route} onItemClick={onItemClick} />
            ))}
          </Wrapper>
        </MenuAccordion>
      )}
      {educationRoutes.length > 0 && (
        <MenuAccordion title="Обучение">
          <Wrapper>
            {educationRoutes.map((item) => (
              <MenuItem {...item} key={item.route} onItemClick={onItemClick} />
            ))}
          </Wrapper>
        </MenuAccordion>
      )}
      <Wrapper>
        {anotherRoutes.map((item) => (
          <MenuItem {...item} key={item.route} onItemClick={onItemClick} />
        ))}
      </Wrapper>
    </>
  )
}

export default MainMenu
