import { createContext, Dispatch, FC, SetStateAction } from 'react'
import { useToggle } from 'react-use'
import { noop } from 'lodash'
import SelectDangers from './SelectDangers'
import DangersFields from './DangersFields'
import ETNField from './ETNField'

export const EtnContext = createContext<{
  enabledEtn: boolean
  toggleEnabledEtn: Dispatch<SetStateAction<boolean>>
}>({ enabledEtn: true, toggleEnabledEtn: noop })

const Dangers: FC = () => {
  const [enabledEtn, toggleEnabledEtn] = useToggle(true)

  return (
    <EtnContext.Provider value={{ enabledEtn, toggleEnabledEtn }}>
      <ETNField />
      <SelectDangers />
      <DangersFields />
    </EtnContext.Provider>
  )
}

export default Dangers
