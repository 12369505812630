import { CompanyDetailed } from '@models/company'
import { Option } from '@organisms/Select'
import setDefaultCurators from '@templates/CuratorsMultilineFields/setDefaultCurators'
import { FormValues } from './index'

type Result = Partial<FormValues & { company: Option }>

const getDefaultValues = (company?: CompanyDetailed): Result | void => {
  if (!company) return

  const result: Result = {
    title: company.title,
    fullTitle: company.fullTitle,
    subscriptionsCount: company.subscriptionsCount
      ? String(company.subscriptionsCount)
      : undefined,
    forcePasswordChange: company.forcePasswordChange,
  }

  if (company.id) {
    result.company = {
      ...company,
      label: company.title,
      value: company['@id'],
    }
  }

  if (company.logo) {
    result.logo = company.logo
  }

  return setDefaultCurators(result, company.curators)
}

export default getDefaultValues
