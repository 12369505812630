import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import { useToggle } from 'react-use'
import { deviceHeight } from '@const/device'
import { DEFAULT_ANIMATION_DURATION } from '@const/animation'
import FooterLinks from '../FooterLinks'
import MainMenu from '../MainMenu'
import CuratorStatus from '../../Header/CuratorStatus'
import { MobWrapper } from './styled'
import MobileHeader from './MobileHeader'

const SMALL_HEIGHT = 65
const BIG_HEIGHT = deviceHeight + SMALL_HEIGHT

type Props = {
  setExpandedMobileMenu: Dispatch<SetStateAction<boolean>>
}

const MobileMenu: FC<Props> = ({ setExpandedMobileMenu }) => {
  const [visibleMenu, toggleVisibleMenu] = useToggle(false)

  const { x } = useSpring({
    x: visibleMenu ? BIG_HEIGHT : SMALL_HEIGHT,
    config: { duration: DEFAULT_ANIMATION_DURATION },
  })

  const handleItemClick = () => {
    toggleVisibleMenu(false)
  }

  useEffect(() => {
    setExpandedMobileMenu(visibleMenu)
  }, [setExpandedMobileMenu, visibleMenu])

  return (
    <>
      <animated.div
        style={{
          width: '100%',
          height: x.to({
            range: [SMALL_HEIGHT, BIG_HEIGHT],
            output: [SMALL_HEIGHT, BIG_HEIGHT],
          }),
          zIndex: 300,
          overflow: 'hidden',
          position: 'absolute',
        }}
      >
        <MobileHeader handleBurgerClick={toggleVisibleMenu} />
        <MobWrapper>
          <MainMenu onItemClick={handleItemClick} smallMenu />
          <FooterLinks visible handleItemClick={handleItemClick} />
          <CuratorStatus />
        </MobWrapper>
      </animated.div>
    </>
  )
}

export default MobileMenu
