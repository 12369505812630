import * as React from 'react'
import { FC } from 'react'
import { useGetDivisionQuery } from '@services/divisions'
import { Subdivision } from '@models/user'
import Title from '@organisms/Title'
import CuratorField from '../CuratorField'

type Props = {
  division: Subdivision
}

const DivisionCurators: FC<Props> = ({ division }) => {
  const { data } = useGetDivisionQuery(division?.id, {
    skip: !division,
  })
  const { curators = [] } = data || {}

  if (!curators?.length) {
    return <Title mySize="h4">Кураторов не найдено</Title>
  }

  return (
    <>
      {curators.map((curator) => (
        <CuratorField
          title="Куратор подразделения"
          id={curator.id}
          key={'filial' + curator.id + curator.fullName}
        />
      ))}
    </>
  )
}

export default DivisionCurators
