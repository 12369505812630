export const INITIAL_BRIEFING_REPORT_NAME =
  'Журнал регистрации вводного инструктажа по охране труда'
export const OTHER_BRIEFING_REPORT_NAME =
  'Журнал регистрации первичного инструктажа, повторный инструктаж с ' +
  'назначением определённого периода 1 раз в 3/6/12 месяцев'

export const BODY_CHECK_REPORT_NAME = 'Медосмотр'
export const EMPLOYEES_REPORT_NAME = 'Статистика обучений'
export const POSITIONS_REPORT_NAME =
  'Перечень должностей ответственных за инструктажи'
export const LINKS_FOR_PASSWORDS_NAME = 'Ссылки для задания паролей сотрудникам'
export const ANTI_FIRE_REPORT_NAME = 'Журнал учета противопожарных инструктажей'
export const ELECTRO_SAFE_REPORT_NAME =
  'Журнал присвоения I группы по электробезопасности неэлектротехническому персоналу'

export const DANGEROUS_REGISTRY = 'Реестр опасностей'
export const RISK_MANAGEMENT_PLAN = 'План мер по управлению рисками'
export const ACK_WITH_RISK_MAPS = 'Ознакомление с картами риска'
export const POSITIONS_WITHOUT_RISK_MAPS = 'Должности без карты рисков'
export const RISK_MANAGEMENT_SYSTEM =
  'Положение: система управления профессиональными рисками'
export const SIZ = 'Средства индивидуальной защиты'
