import { FC, ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'
import { BaseApiResponse } from '@models/common'
import MultilineFieldNew from '@templates/MultilineFieldNew'
import { log } from '@utils/console'
import { FieldProps } from '../../Field'
import {
  ContentBeforeItemsWrapper,
  ContentWrapper,
  MultilineFieldWrapper,
} from './styled'
import LineFields from './LineFields'

export type Field = FieldProps
export type FieldsItem = {
  fields: Field[]
  lineTitle?: string
  isMultiline?: boolean
  isDisabledMultiline?: boolean
  preselectedItems?: BaseApiResponse[]
  lineId?: string
}
export type Fields = FieldsItem[]

type Props = {
  fields: Fields
  contentBeforeFields?: ReactNode
  contentAfterFields?: ReactNode
}

const Content: FC<Props> = ({
  fields,
  contentBeforeFields,
  contentAfterFields,
}) => {
  const {
    formState: { errors },
  } = useFormContext()

  log('form errors:', errors)

  return (
    <ContentWrapper id="content">
      {contentBeforeFields && (
        <ContentBeforeItemsWrapper>
          {contentBeforeFields}
        </ContentBeforeItemsWrapper>
      )}
      <div id="contentFields">
        {fields.map((element) =>
          element.isMultiline ? (
            <MultilineFieldWrapper key={element?.fields[0]?.name}>
              <MultilineFieldNew
                fieldsName="employees"
                fieldsSrc={element.fields}
                amendValue={{}}
                isDisabled={element.isDisabledMultiline}
              />
            </MultilineFieldWrapper>
          ) : (
            <LineFields
              fields={element.fields}
              key={element?.fields[0]?.name}
              lineTitle={element.lineTitle}
              id={element.lineId}
            />
          )
        )}
      </div>
      {contentAfterFields && (
        <ContentBeforeItemsWrapper>
          {contentAfterFields}
        </ContentBeforeItemsWrapper>
      )}
    </ContentWrapper>
  )
}

export type { Props as ContentProps }
export default Content
