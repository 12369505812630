import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const DesktopWrapper = styled.div`
  display: flex;
`
const MobileWrapper = styled(DesktopWrapper)`
  position: absolute;
  z-index: 500;
  background-color: white;
  padding: 25px;
  width: 100%;
`
export const Wrapper = isMobile ? MobileWrapper : DesktopWrapper

export const BurgerWrapper = styled.div`
  cursor: pointer;
  margin-right: 13px;
  margin-left: 5px;
`

export const MainLogoWrapper = styled.div`
  padding-top: ${() => (isMobile ? '1px' : '3px')};
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const BellWrapper = styled.div`
  margin-top: -10px;
  margin-right: -10px;
  position: absolute;
  z-index: 9999;
  right: 10px;
  top: 25px;
`
