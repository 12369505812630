import { FC, useContext } from 'react'
import { Switch } from 'antd'
import { EtnContext } from '@modals/RiskMapModal/Dangers'
import { BottomWrapper, EtnSubTitle, EtnTitle, Wrapper } from './styled'

const ETNField: FC = () => {
  const { enabledEtn, toggleEnabledEtn } = useContext(EtnContext)

  return (
    <Wrapper>
      <EtnTitle>Выберите тип наименования</EtnTitle>
      <BottomWrapper>
        <EtnSubTitle>Только по ЕТН</EtnSubTitle>
        <Switch
          checked={!enabledEtn}
          onChange={() => toggleEnabledEtn((prevState) => !prevState)}
        />
        <EtnSubTitle>Все опасности</EtnSubTitle>
      </BottomWrapper>
    </Wrapper>
  )
}

export default ETNField
