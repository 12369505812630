import { Dispatch, FC, SetStateAction } from 'react'
import { NavigateFunction } from 'react-router-dom'
import useMe from '@hooks/useMe'
import { FilterState } from '@hooks/useItemsFilters'
import { setSearchValue } from '@utils/setSearchValue'
import ButtonStyle from '@enums/ButtonStyle'
import { OnboardingProps } from '@templates/Onboarding'
import OnboardingCodes from '@enums/OnboardingCodes'
import ResetOnboarding from '@templates/ResetOnboarding'
import SearchInput from './SearchInput'
import AddButton from './AddButton'
import {
  AddButtonWrapper,
  LeftWrapper,
  ResetOnboardingWrapper,
  RightWrapper,
  SearchWrapper,
  TitleWrapper,
  Wrapper,
} from './styled'
import Title, { TitleProps } from './Title'
import ReportsButton, { ReportsProp } from './ReportsButton'

type Props = {
  selected?: number
  titleTabs?: Omit<TitleProps, 'onChangeSelected' | 'selected'>[]
  ModalContent?: FC<{ handleCloseModal: () => void }>
  SecondaryModalContent?: FC<{ handleCloseModal: () => void }>
  isLoading?: boolean
  addButtonTitle?: string
  secondaryAddButtonTitle?: string
  setFilters?: Dispatch<SetStateAction<FilterState>>
  withSearch?: boolean
  navigate?: NavigateFunction
  setFiltersState: Dispatch<SetStateAction<FilterState>>
  searchPlaceholder?: string
  reports?: ReportsProp
  reportsOnboarding?: OnboardingProps
  resetOnboardingCodes?: OnboardingCodes[]
  onResetOnboarding?: () => void
}

const Header: FC<Props> = ({
  ModalContent,
  SecondaryModalContent,
  selected = 0,
  titleTabs,
  isLoading,
  addButtonTitle,
  secondaryAddButtonTitle,
  setFilters,
  withSearch = false,
  reports,
  navigate,
  setFiltersState,
  searchPlaceholder,
  reportsOnboarding,
  resetOnboardingCodes,
  onResetOnboarding,
}) => {
  const { isEmployee } = useMe()

  const handleSearch = (value: string) => {
    setFilters?.(setSearchValue(value))
    setFiltersState?.(setSearchValue(value))
  }

  return (
    <>
      <Wrapper>
        <LeftWrapper>
          {titleTabs && (
            <TitleWrapper>
              {titleTabs.map((item) => (
                <Title
                  {...item}
                  key={item.index}
                  selected={selected}
                  isLoading={isLoading}
                  navigate={navigate}
                />
              ))}
            </TitleWrapper>
          )}
          {withSearch && (
            <SearchWrapper id="search-block">
              <SearchInput
                placeholder={searchPlaceholder || 'Поиск'}
                onSearch={handleSearch}
              />
            </SearchWrapper>
          )}
        </LeftWrapper>
        <RightWrapper>
          {reports && !isEmployee && (
            <ReportsButton
              reports={reports}
              reportsOnboarding={reportsOnboarding}
            />
          )}
          {SecondaryModalContent && (
            <AddButton
              ModalContent={SecondaryModalContent}
              addButtonTitle={secondaryAddButtonTitle}
              buttonStyle={ButtonStyle.SECONDARY}
              id="secondary-add-button"
            />
          )}
          {ModalContent && (
            <AddButtonWrapper>
              <AddButton
                ModalContent={ModalContent}
                addButtonTitle={addButtonTitle}
              />
            </AddButtonWrapper>
          )}
        </RightWrapper>
      </Wrapper>
      {resetOnboardingCodes && (
        <ResetOnboardingWrapper>
          <ResetOnboarding
            resetOnboardingCodes={resetOnboardingCodes}
            onResetOnboarding={onResetOnboarding}
          />
        </ResetOnboardingWrapper>
      )}
    </>
  )
}

export default Header
