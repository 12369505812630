import { Option } from '@organisms/Select'
import { Course } from './course'
import { File } from './file'
import { BaseApiResponse } from './common'

export type Scorm = {
  id: string
  title: string
  file: File
  scormDirectory: string
  currentVote: ScormVotes | null
  courseChapterThemes: CourseChapterTheme[]
  scormType: 'ispring' | 'articulate'
  badVotes: number
  excellentVotes: number
  goodVotes: number
} & BaseApiResponse

export type CourseChapterTheme = {
  id: number
  title: string
  courseTheme: string
  courseChapter: CourseChapter
} & BaseApiResponse

type CourseChapter = {
  id: number
  title: string
  hoursCount: number
  course: Course
  themesCount: number
} & BaseApiResponse

export type ScormListItemServerResponse = {
  id: string
  title: string
} & BaseApiResponse

export type ScormListItem = ScormListItemServerResponse & Option

export type CommonScormBody = {
  title: string
  file: string
}

export type CreateScormBody = CommonScormBody

export type UpdateScormBody = CommonScormBody & {
  id: string
}

export type VoteScormBody = {
  id: string
  rank: ScormVotes
}

export enum ScormVotes {
  BAD = 'bad',
  GOOD = 'good',
  EXCELLENT = 'excellent',
}
