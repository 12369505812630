import { UseFormReturn } from 'react-hook-form'
import { useEffect } from 'react'
import { Company } from '@models/company'
import { Filial } from '@models/filial'
import { Division } from '@models/division'

const useJobCleaner = ({
  watch,
  setValue,
  formState: { isDirty },
}: UseFormReturn): void => {
  const company: Company | undefined = watch('company')
  const filial: Filial | undefined = watch('filial')
  const division: Division | undefined = watch('division')

  useEffect(() => {
    if (isDirty) {
      setValue('filial', null)
      setValue('division', null)
      setValue('position', null)
    }
  }, [company])

  useEffect(() => {
    if (isDirty) {
      setValue('division', null)
      setValue('position', null)
    }
  }, [filial])

  useEffect(() => {
    if (isDirty) {
      setValue('position', null)
    }
  }, [division])
}

export default useJobCleaner
