import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 20px 0;

  @media (min-height: 700px) {
    gap: 30px;
  }

  ${isMobile ? 'padding: 30px 0;' : ''}
`
