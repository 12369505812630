import React, { ReactNode } from 'react'
import { ReactComponent as InactiveCurator } from '@main-menu/inactive/curator.svg'
import { ReactComponent as InactiveLibrary } from '@main-menu/inactive/library.svg'
import { ReactComponent as InactiveSettings } from '@main-menu/inactive/settings.svg'
import { ReactComponent as InactiveStatistic } from '@main-menu/inactive/statistic.svg'
import { ReactComponent as InactiveStudy } from '@main-menu/inactive/study.svg'
import { ReactComponent as InactiveTariff } from '@main-menu/inactive/tariff.svg'
import { ReactComponent as InactiveCourses } from '@main-menu/inactive/courses.svg'
import { ReactComponent as InactiveCertificate } from '@main-menu/inactive/certificates.svg'
import { ReactComponent as InactiveCompanyGroups } from '@main-menu/inactive/company-groups.svg'
import { ReactComponent as InactiveCompanies } from '@main-menu/inactive/companies.svg'
import { ReactComponent as InactiveDivisions } from '@main-menu/inactive/divisions.svg'
import { ReactComponent as InactivePositions } from '@main-menu/inactive/positions.svg'
import { ReactComponent as InactiveEmployees } from '@main-menu/inactive/employees.svg'
import { ReactComponent as InactiveFilials } from '@main-menu/inactive/filials.svg'
import { ReactComponent as InactiveEducations } from '@main-menu/inactive/educations.svg'
import { ReactComponent as InactiveRisks } from '@main-menu/inactive/risks.svg'
import { ReactComponent as ActiveTariff } from '@main-menu/active/tariff.svg'
import { ReactComponent as ActiveStudy } from '@main-menu/active/study.svg'
import { ReactComponent as ActiveStatistic } from '@main-menu/active/statistic.svg'
import { ReactComponent as ActiveSettings } from '@main-menu/active/settings.svg'
import { ReactComponent as ActiveLibrary } from '@main-menu/active/library.svg'
import { ReactComponent as ActiveCurator } from '@main-menu/active/curator.svg'
import { ReactComponent as ActiveCourses } from '@main-menu/active/courses.svg'
import { ReactComponent as ActiveCertificate } from '@main-menu/active/certificates.svg'
import { ReactComponent as ActiveCompanyGroups } from '@main-menu/active/company-groups.svg'
import { ReactComponent as ActiveCompanies } from '@main-menu/active/companies.svg'
import { ReactComponent as ActiveDivisions } from '@main-menu/active/divisions.svg'
import { ReactComponent as ActivePositions } from '@main-menu/active/positions.svg'
import { ReactComponent as ActiveEmployees } from '@main-menu/active/employees.svg'
import { ReactComponent as ActiveFilials } from '@main-menu/active/filials.svg'
import { ReactComponent as ActiveEducations } from '@main-menu/active/educations.svg'
import { ReactComponent as ActiveRisks } from '@main-menu/active/risks.svg'
import { Roles } from '@enums/Roles'
import { Routes } from '@enums/Routes'
import { Icon } from '@app-types/common'
import Certificates from '@pages/Certificates'
import Courses from '@pages/Courses'
import Curator from '@pages/Curator'
import Init from '@pages/Init'
import Library from '@pages/Library'
import Profile from '@pages/Profile'
import Settings from '@pages/Settings'
import Statistic from '@pages/Statistic'
import Study from '@pages/Study'
import Tariff from '@pages/Tariff'
import Theme from '@pages/Theme'
import Themes from '@pages/Themes'
import Course from '@pages/Course'
import EnrollmentsHistory from '@pages/EnrollmentsHistory'
import PrivacyPolicy from '@pages/PrivacyPolicy'
import Positions from '@pages/Positions'
import Filials from '@pages/Filials'
import Divisions from '@pages/Divisions'
import Companies from '@pages/Companies'
import Employees from '@pages/Employees'
import EmployeesEducation from '@pages/EmployeesEducation'
import FilialSettings from '@pages/FilialSettings'
import CompanyModalOnRoute from '@modals/CompanyModalOnRoute'
import FilialModalOnRoute from '@modals/FilialModalOnRoute'
import DivisionModalOnRoute from '@modals/DivisionModalOnRoute'
import PositionModalOnRoute from '@modals/PositionModalOnRoute'
import EmployeeModalOnRoute from '@modals/EmployeeModalOnRoute'
import CompanySettings from '@pages/CompanySettings'
import RiskMaps from '@pages/RiskMaps'
import Instructions from '@pages/Instructions'
import RiskMapsEmployee from '@pages/RiskMapsEmployee'
import Instruction from '@pages/Instruction'
import { Me } from '@models/me'
import DivisionSettings from '@pages/DivisionSettings'
import CompanyGroups from '@pages/CompanyGroups'
import CompanyGroupsCuratorTariff from '@pages/CompanyGroupsCuratorTariff'

export type RouteConfig = {
  ActiveIcon?: Icon
  InactiveIcon?: Icon
  text?: string
  getText?: (me: Me) => string
  route: Routes
  routesForMatch?: Routes[]
  onItemClick?: (route: string) => void
  roles?: Set<Roles>
  element: ReactNode
}

const ROUTES_CONFIG: RouteConfig[] = [
  {
    ActiveIcon: ActiveCompanyGroups,
    InactiveIcon: InactiveCompanyGroups,
    text: 'Группы компаний',
    route: Routes.COMPANY_GROUPS,
    roles: new Set([Roles.ADMIN]),
    element: <CompanyGroups />,
  },
  {
    ActiveIcon: ActiveCompanies,
    InactiveIcon: InactiveCompanies,
    text: 'Компании',
    route: Routes.COMPANIES,
    roles: new Set([Roles.ADMIN, Roles.COMPANY_GROUP_CURATOR]),
    element: <Companies />,
  },
  {
    ActiveIcon: ActiveFilials,
    InactiveIcon: InactiveFilials,
    text: 'Филиалы',
    route: Routes.FILIALS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
    ]),
    element: <Filials />,
  },
  {
    ActiveIcon: ActiveDivisions,
    InactiveIcon: InactiveDivisions,
    text: 'Подразделения',
    route: Routes.DIVISIONS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
    ]),
    element: <Divisions />,
  },
  {
    ActiveIcon: ActivePositions,
    InactiveIcon: InactivePositions,
    text: 'Должности',
    route: Routes.POSITIONS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <Positions />,
  },
  {
    ActiveIcon: ActiveEmployees,
    InactiveIcon: InactiveEmployees,
    text: 'Сотрудники',
    route: Routes.EMPLOYEES,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <Employees />,
  },
  {
    ActiveIcon: ActiveEducations,
    InactiveIcon: InactiveEducations,
    text: 'Обучение сотрудников',
    route: Routes.EMPLOYEES_EDUCATION,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <EmployeesEducation />,
  },
  {
    ActiveIcon: ActiveRisks,
    InactiveIcon: InactiveRisks,
    text: 'Карты рисков',
    route: Routes.RISKS_MAPS,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <RiskMaps />,
  },
  {
    ActiveIcon: ActiveRisks,
    InactiveIcon: InactiveRisks,
    text: 'Карты рисков (ознакомление)',
    getText: (me) =>
      me.isEmployee ? 'Карты рисков' : 'Карты рисков (ознакомление)',
    route: Routes.RISKS_MAPS_REVIEW,
    roles: new Set([
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
      Roles.EMPLOYEE,
    ]),
    element: <RiskMapsEmployee />,
  },
  {
    ActiveIcon: ActiveTariff,
    InactiveIcon: InactiveTariff,
    text: 'Тариф',
    route: Routes.TARIFF,
    routesForMatch: [Routes.ENROLLMENT_HISTORY],
    roles: new Set([Roles.COMPANY_CURATOR, Roles.FILIAL_CURATOR]),
    element: <Tariff />,
  },
  {
    ActiveIcon: ActiveTariff,
    InactiveIcon: InactiveTariff,
    text: 'Тариф',
    route: Routes.TARIFF,
    routesForMatch: [Routes.ENROLLMENT_HISTORY],
    roles: new Set([Roles.COMPANY_GROUP_CURATOR]),
    element: <CompanyGroupsCuratorTariff />,
  },
  {
    ActiveIcon: ActiveStudy,
    InactiveIcon: InactiveStudy,
    text: 'Мое обучение',
    route: Routes.STUDY,
    roles: new Set([
      Roles.EMPLOYEE,
      Roles.FILIAL_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <Study />,
  },
  {
    ActiveIcon: ActiveStatistic,
    InactiveIcon: InactiveStatistic,
    text: 'Статистика',
    route: Routes.STATISTIC,
    roles: new Set([
      Roles.EMPLOYEE,
      Roles.DIVISION_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
    ]),
    element: <Statistic />,
  },
  {
    ActiveIcon: ActiveCertificate,
    InactiveIcon: InactiveCertificate,
    text: 'Мои сертификаты',
    route: Routes.CERTIFICATES,
    roles: new Set([
      Roles.EMPLOYEE,
      Roles.FILIAL_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <Certificates />,
  },
  {
    ActiveIcon: ActiveCourses,
    InactiveIcon: InactiveCourses,
    text: 'Витрина курсов',
    route: Routes.COURSES,
    routesForMatch: [Routes.THEMES, Routes.COURSE, Routes.THEME],
    element: <Courses />,
  },
  {
    route: Routes.THEMES,
    element: <Themes />,
  },
  {
    ActiveIcon: ActiveLibrary,
    InactiveIcon: InactiveLibrary,
    text: 'Библиотека',
    route: Routes.LIBRARY,
    element: <Library />,
  },
  {
    ActiveIcon: ActiveCurator,
    InactiveIcon: InactiveCurator,
    text: 'Куратор',
    route: Routes.CURATOR,
    roles: new Set([Roles.EMPLOYEE]),
    element: <Curator />,
  },
  {
    ActiveIcon: ActiveSettings,
    InactiveIcon: InactiveSettings,
    text: 'Настройки портала',
    route: Routes.SETTINGS,
    roles: new Set([Roles.ADMIN]),
    element: <Settings />,
  },
  {
    ActiveIcon: ActiveCompanies,
    InactiveIcon: InactiveCompanies,
    text: 'Настройки компании',
    route: Routes.COMPANY_SETTINGS,
    roles: new Set([Roles.COMPANY_CURATOR]),
    element: <CompanySettings />,
  },
  {
    ActiveIcon: ActiveFilials,
    InactiveIcon: InactiveFilials,
    text: 'Настройки филиала',
    route: Routes.FILIAL_SETTINGS,
    roles: new Set([Roles.FILIAL_CURATOR]),
    element: <FilialSettings />,
  },
  {
    ActiveIcon: ActiveDivisions,
    InactiveIcon: InactiveDivisions,
    text: 'Настройки подразделения',
    route: Routes.DIVISION_SETTINGS,
    roles: new Set([Roles.DIVISION_CURATOR]),
    element: <DivisionSettings />,
  },
  {
    text: 'Init',
    route: Routes.INIT,
    element: <Init />,
  },
  {
    route: Routes.ENROLLMENT_HISTORY,
    roles: new Set([
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
    element: <EnrollmentsHistory />,
  },
  {
    route: Routes.PROFILE,
    element: <Profile />,
  },
  {
    route: Routes.THEME,
    element: <Theme />,
  },
  {
    route: Routes.COURSE,
    element: <Course />,
  },
  {
    route: Routes.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    route: Routes.GUIDES,
    element: <Instructions />,
  },
  {
    route: Routes.GUIDE,
    element: <Instruction />,
  },
  {
    route: Routes.COMPANY_MODAL,
    element: <CompanyModalOnRoute />,
    roles: new Set([Roles.ADMIN, Roles.COMPANY_GROUP_CURATOR]),
  },
  {
    route: Routes.FILIAL_MODAL,
    element: <FilialModalOnRoute />,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
    ]),
  },
  {
    route: Routes.DIVISION_MODAL,
    element: <DivisionModalOnRoute />,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
    ]),
  },
  {
    route: Routes.POSITION_MODAL,
    element: <PositionModalOnRoute />,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
  {
    route: Routes.EMPLOYEE_MODAL,
    element: <EmployeeModalOnRoute />,
    roles: new Set([
      Roles.ADMIN,
      Roles.COMPANY_GROUP_CURATOR,
      Roles.COMPANY_CURATOR,
      Roles.FILIAL_CURATOR,
      Roles.DIVISION_CURATOR,
    ]),
  },
]

export default ROUTES_CONFIG
