import { FC } from 'react'
import { useToggle } from 'react-use'
import { UseFormReturn } from 'react-hook-form'
import { isMobile } from '@const/device'
import BlueBorderCard from '@organisms/BlueBorderCard'
import { Fields } from '@templates/AddNewItemModal/Content'
import { FilterState } from '@hooks/useItemsFilters'
import FiltersModal from './FiltersModal'

type Props = {
  methods: UseFormReturn
  title: string
  filterModalFields: Fields
  onSubmitFilter: (form: FilterState) => void
  isLoading: boolean
  transformFormValues?: (form: any) => any
  onSelectReport?: () => void
  id?: string
}

const ReportCard: FC<Props> = ({
  title,
  filterModalFields,
  onSubmitFilter,
  isLoading,
  methods,
  transformFormValues,
  onSelectReport,
  id,
}) => {
  const [filtersIsVisible, toggleFiltersIsVisible] = useToggle(false)

  const onModalClose = () => {
    methods.reset((formValues) => {
      const resultForm: any = {}
      for (const key in formValues) {
        resultForm[key] = null
      }

      return resultForm
    })

    toggleFiltersIsVisible(false)
  }

  return (
    <>
      <BlueBorderCard
        title={title}
        onClick={() => {
          toggleFiltersIsVisible(true)
          onSelectReport?.()
        }}
        key={title}
        width={isMobile ? undefined : 380}
        id={id}
      />
      <FiltersModal
        transformFormValues={transformFormValues}
        methods={methods}
        handleSubmit={onSubmitFilter}
        fields={filterModalFields}
        visible={filtersIsVisible}
        onModalClose={onModalClose}
        isLoading={isLoading}
      />
    </>
  )
}

export default ReportCard
