import { FC, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import { useToggle } from 'react-use'
import { useMediaQuery } from 'react-responsive'
import { useTheme } from '@emotion/react'
import { DEFAULT_ANIMATION_DURATION } from '@const/animation'
import FooterLinks from '../FooterLinks'
import MainLogo from '../MainLogo'
import MainMenu from '../MainMenu'
import { xl } from '../../../theme/breakpoints'
import { MenuWrapper } from './styled'

export const SMALL_WIDTH = 50
export const BIG_WIDTH_MENU = 335

type Props = {
  onExpandMenu: (expanded: boolean) => void
}

const DesktopMenu: FC<Props> = ({ onExpandMenu }) => {
  const theme = useTheme()
  const isTablet = useMediaQuery({ query: `(max-width: ${xl})` })

  const [isSmall, toggleIsSmall] = useToggle(isTablet)
  const [smallVisible, toggleSmallVisible] = useToggle(isTablet)

  useEffect(() => {
    setTimeout(
      () => {
        onExpandMenu(isSmall)
      },
      isSmall ? DEFAULT_ANIMATION_DURATION : 0
    )
  }, [isSmall])

  useEffect(() => {
    toggleIsSmall(isTablet)
  }, [isTablet])

  const { x } = useSpring({
    x: isSmall ? SMALL_WIDTH : BIG_WIDTH_MENU,
    config: { duration: DEFAULT_ANIMATION_DURATION },
    onRest: toggleSmallVisible,
  })

  return (
    <animated.div
      style={{
        backgroundColor: theme.colors.background.surface,
        borderRadius: '0 30px 30px 0',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100vh',
        whiteSpace: 'nowrap',
        padding: '30px 0 30px 15px',
        width: x.to({
          range: [SMALL_WIDTH, BIG_WIDTH_MENU],
          output: [SMALL_WIDTH, BIG_WIDTH_MENU],
        }),
        maxWidth: x.to({
          range: [SMALL_WIDTH, BIG_WIDTH_MENU],
          output: [SMALL_WIDTH, BIG_WIDTH_MENU],
        }),
        minWidth: x.to({
          range: [SMALL_WIDTH, BIG_WIDTH_MENU],
          output: [SMALL_WIDTH, BIG_WIDTH_MENU],
        }),
        overflowY: 'auto',
        overflowX: 'hidden',
        position: 'fixed',
        zIndex: 1000,
      }}
    >
      <MenuWrapper>
        <MainLogo handleBurgerClick={toggleIsSmall} />
        <MainMenu smallMenu={isSmall} />
      </MenuWrapper>
      <FooterLinks visible={!smallVisible} />
    </animated.div>
  )
}

export default DesktopMenu
