import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateScormBody,
  Scorm,
  ScormListItem,
  ScormListItemServerResponse,
  UpdateScormBody,
  VoteScormBody,
} from '@models/scorm'
import StoreTags from '@enums/StoreTags'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/course_themes'

export const scormsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getScorms: builder.query<
      {
        items: ScormListItem[]
        length: number
      },
      CommonQueryParams
    >({
      providesTags: [StoreTags.SCORMS],
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (
        response: ItemsFromResponse<ScormListItemServerResponse>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
    }),
    getScormById: builder.query<Scorm, string | number>({
      keepUnusedDataFor: 1,
      query: (id) => `${BASE_URL}/${id}`,
    }),
    postScorm: builder.mutation<Scorm, CreateScormBody>({
      invalidatesTags: (result) => (result ? [StoreTags.SCORMS] : []),
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    putScorm: builder.mutation<Scorm, UpdateScormBody>({
      invalidatesTags: (result) =>
        result ? [StoreTags.SCORMS, StoreTags.SCORM] : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteScorm: builder.mutation<void, number | string>({
      invalidatesTags: [
        StoreTags.SCORMS,
        StoreTags.EMPLOYEE_EDUCATIONS,
        StoreTags.COURSES,
      ],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
    voteScorm: builder.mutation<void, VoteScormBody>({
      invalidatesTags: [
        StoreTags.SCORMS,
        StoreTags.SCORM,
        StoreTags.COURSE_THEME,
      ],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}/vote`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetScormsQuery,
  useDeleteScormMutation,
  usePostScormMutation,
  usePutScormMutation,
  useGetScormByIdQuery,
  useVoteScormMutation,
} = scormsApi
