/* eslint-disable sonarjs/no-duplicate-string */
import * as React from 'react'
import { FC, ReactNode, useEffect } from 'react'
import { compact } from 'lodash'
import Table, { Column } from '@organisms/Table'
import { RiskMapDetailed } from '@models/riskMap'
import RiskSignificance from '@templates/RiskSignificance'
import { getPossibilityLabel, getSeverityLabel } from '@utils/riskMaps'
import EditRiskMapButton from '@pages/RiskMaps/RiskMap/Body/EditRiskMapButton'
import Meres from './Meres'
import { EditBtnWrapper, Wrapper } from './styled'

const COLUMNS: Column[] = [
  {
    title: 'Опасность / опасное событие',
    dataIndex: 'dangerous',
  },
  {
    title: 'Объект возникновения опасности',
    dataIndex: 'object',
  },
  {
    title: 'Степень тяжести',
    dataIndex: 'grade',
  },
  {
    title: 'Вероятность получения травмы',
    dataIndex: 'traumaRisk',
  },
  {
    title: 'Значимость риска',
    dataIndex: 'importance',
    width: 250,
  },
  {
    title: 'Меры',
    dataIndex: 'meres',
  },
  {
    title: '',
    dataIndex: 'edit',
  },
]

type DataItem = {
  dangerous: string
  object: string
  grade: string
  traumaRisk: string
  importance: ReactNode
  meres: ReactNode
  edit: ReactNode
  key: string
}

type Props = {
  onChangeSize: () => void
  riskMap: RiskMapDetailed
  index: number
}

const Dangers: FC<Props> = ({ riskMap, onChangeSize, index }) => {
  const items = riskMap.dangers

  useEffect(() => {
    onChangeSize()
  }, [items])

  const tabs: DataItem[] = items.map(
    (
      {
        '@id': idVal,
        hazard,
        event,
        object,
        injurySeverity,
        injuryPossibility,
        riskMapSignificance,
        riskMapDangerControlMeasures,
      },
      dangersIndex
    ) => ({
      key: idVal,
      dangerous: compact([hazard?.value, event?.value]).join(' / '),
      object: object?.value || '',
      grade: getSeverityLabel(injurySeverity),
      traumaRisk: getPossibilityLabel(injuryPossibility),
      importance: <RiskSignificance children={riskMapSignificance} />,
      meres: (
        <Meres
          children={riskMapDangerControlMeasures}
          riskMap={riskMap}
          dangersIndex={dangersIndex}
        />
      ),
      edit: (
        <EditBtnWrapper>
          <EditRiskMapButton riskMap={riskMap} dangersIndex={dangersIndex} />
        </EditBtnWrapper>
      ),
    })
  )

  return (
    <Wrapper id={`dangers-tab-content-${index}`}>
      <Table columns={COLUMNS} data={tabs} />
    </Wrapper>
  )
}

export default Dangers
