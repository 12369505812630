import * as React from 'react'
import { FC } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Field from '@templates/Field'
import DeleteButton from '@templates/DeleteButton'
import AddButton from '@organisms/AddButton'
import { FieldForRender } from '@templates/AddNewItemModal'
import { Option } from '@organs/Select'
import {
  AddButtonWrapper,
  Container,
  DeleteWrapper,
  StyledFlexWrapper,
} from './styled'

type Props = {
  fieldsName: string
  fieldsSrc: FieldForRender[]
  amendValue: any
  addLabel?: string
  isDisabled?: boolean
  addButtonId?: string
  fieldId?: string
}

const MultilineFieldNew: FC<Props> = ({
  fieldsName,
  fieldsSrc,
  amendValue,
  addLabel = 'Добавить',
  isDisabled = false,
  addButtonId = 'add-button-multiline',
  fieldId,
}) => {
  const methods = useFormContext()
  const { control, getValues } = methods

  const { fields, append, remove } = useFieldArray({
    name: fieldsName,
    control,
  })

  const selectedMultilineValues =
    (getValues()[fieldsName] as Option[])?.map((option) => option?.value) || []

  return (
    <Container id={fieldId}>
      {fields.map((field, index) => {
        return (
          <div key={field.id}>
            <StyledFlexWrapper>
              {fieldsSrc.map((item) => (
                <Field
                  key={item.name}
                  {...item}
                  selectedMultilineValues={selectedMultilineValues}
                  name={
                    fieldsSrc.length === 1
                      ? `${fieldsName}.${index}`
                      : `${fieldsName}.${index}.${item.name}`
                  }
                />
              ))}
              <DeleteWrapper>
                <DeleteButton type="button" onClick={() => remove(index)} />
              </DeleteWrapper>
            </StyledFlexWrapper>
          </div>
        )
      })}

      <AddButtonWrapper>
        <AddButton
          id={addButtonId}
          type="button"
          onClick={() => append(amendValue)}
          disabled={isDisabled}
        >
          {addLabel}
        </AddButton>
      </AddButtonWrapper>
    </Container>
  )
}

export default MultilineFieldNew
