import { FC, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { notification } from 'antd'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
} from '@services/employees'
import useMe from '@hooks/useMe'
import { User } from '@models/user'
import { CommonEmployeeBody } from '@models/employee'
import AddNewItemModal from '@templates/AddNewItemModal'
import { Option } from '@organisms/Select'
import DivisionPositionMultilineFields from '@templates/DivisionPositionMultiline'
import OnboardingCodes from '@enums/OnboardingCodes'
import { UserConfig } from '@models/me'
import useEmployeeFields from './useEmployeeFields'
import IntershipProgramTable from './IntershipProgramTable'
import getDefaultValues from './getDefaultValues'
import AddManyEmployees from './AddManyEmployees'
import {
  getEmployeeProbationSheetsFromForm,
  getSubdivisionJobPositionRels,
} from './utils'
import Footer from './Footer'
import { ONBOARDING_STEPS, ONBOARDING_TITLE } from './onboarding'

export type FormValues = {
  company: Option
  filial: Option
  fullName: string
  phone: string
  email: string
  birthDate?: string
  employmentDate?: string
  education?: string
  nextBodyCheckDate?: string
  comment?: string
  inn?: string
  insuranceNumber?: string
  userConfig: UserConfig | null
  subdivisionJobPositionRels: {
    division: Option
    position: Option
  }[]
  // employeeProbationSheets: [
  //   {
  //     title: string
  //     issueDate: string
  //     responsibleOfProbation: string
  //     probationShiftCount: number
  //     probationStartAt: string
  //     probationEndAt: string
  //     probationSender: string
  //     probationReason: string
  //     probationConclusion: string
  //     workPermittedAt: string
  //     probationSheetQuestions: {
  //       question: string
  //       date: string
  //     }[]
  //   }
  // ]
} & Record<string, any>

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  employee?: User
  initActiveList?: number
}

const EmployeeModal: FC<Props> = ({
  isEdit,
  employee,
  handleCloseModal,
  initActiveList,
}) => {
  const { state } = useLocation()
  const { companyBranch, divisionCuratorWithOneDivision } = useMe()

  const [, setSavedFormState] = useState<FieldValues>()
  const [intershipList, setIntershipList] = useState([0])

  const [create, createData] = useCreateEmployeeMutation()
  const [update, updateData] = useUpdateEmployeeMutation()
  const additionalProps = isEdit ? updateData : createData

  const methods = useFormProcessor({
    apiErrors: additionalProps.error,
    defaultValues: getDefaultValues(state || employee),
  })

  console.log(methods.watch('phone'))

  const { fields, selectedTab, listActive, setListActive } = useEmployeeFields(
    setSavedFormState,
    intershipList,
    methods,
    initActiveList
  )

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleSubmitForm = (form: FormValues) => {
    if (!isEmpty(methods.formState.errors)) {
      return
    }

    const employeeProbationSheets = getEmployeeProbationSheetsFromForm(form)

    const subdivisionJobPositionRels = getSubdivisionJobPositionRels(
      form,
      divisionCuratorWithOneDivision
    )

    if (subdivisionJobPositionRels.length > 0) {
      let index = 0
      for (const { jobPosition, subdivision } of subdivisionJobPositionRels) {
        if (!isEmpty(subdivision) && isEmpty(jobPosition)) {
          notification.error({
            message: `Необходимо указать должность в ${
              index + 1
            } строке должности и подразделения`,
          })
          return
        }
        index++
      }
    }

    const commonForm: CommonEmployeeBody = {
      companyGroup: undefined,
      companyBranch: form.filial?.value || companyBranch['@id'],
      fullName: form.fullName,
      phone: form.phone || null,
      email: form.email || null,
      birthDate: form.birthDate || null,
      comment: form.comment || null,
      education: form.education || null,
      inn: form.inn || null,
      insuranceNumber: form.insuranceNumber || null,
      employmentDate: form.employmentDate || null,
      nextBodyCheckDate: form.nextBodyCheckDate || null,
      subdivisionJobPositionRels,
      employeeProbationSheets,
      curatorStatus: undefined,
      userConfig: form.userConfig,
    }

    if (isEdit) {
      if (employee) {
        update({
          ...commonForm,
          id: employee.id,
        })
      }
    } else {
      create(commonForm)
    }
  }

  return (
    <AddNewItemModal
      {...additionalProps}
      CustomFooter={isEdit ? undefined : Footer}
      additionalTitleContent={
        isEdit ? undefined : (
          <AddManyEmployees handleCloseModal={handleCloseModal} />
        )
      }
      isEdit={isEdit}
      titlePostfix="сотрудника"
      fields={fields}
      onSubmitForm={handleSubmitForm}
      handleCloseModal={handleCloseModal}
      methods={methods}
      withSaveAndAddMore
      onSaveAndAddMore={() => setSavedFormState({})}
      contentAfterFields={
        <>
          {selectedTab !== 1 && <DivisionPositionMultilineFields />}
          <IntershipProgramTable
            methods={methods}
            setIntershipList={setIntershipList}
            listActive={listActive}
            setListActive={setListActive}
            employeeProbationSheets={employee?.employeeProbationSheets}
            hidden={selectedTab === 0}
          />
        </>
      }
      withoutAddMore
      onboarding={{
        steps: ONBOARDING_STEPS,
        firstStepTitle: ONBOARDING_TITLE,
        code: OnboardingCodes.employee_create,
        withScroll: true,
        withFeedback: true,
      }}
    />
  )
}

export default EmployeeModal
