import { FC } from 'react'
import Prompt from '@organisms/Prompt'
import { Wrapper } from './styled'

const Inactive: FC = () => {
  return (
    <Wrapper>
      <span>Ознакомиться</span>
      <Prompt
        promptText="Чтобы ознакомиться с картой рисков, скачайте её"
        width={300}
      />
    </Wrapper>
  )
}

export default Inactive
