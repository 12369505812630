import { Collapse } from 'antd'
import React, { ReactNode } from 'react'
import { useToggle } from 'react-use'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { HeaderWrapper } from './styled'

const { Panel } = Collapse
const ICON_SIZE = 10

type Props = {
  children: ReactNode
  title: string
  initialExpanded?: boolean
}

const MenuAccordion: React.FC<Props> = ({
  children,
  title,
  initialExpanded = false,
}) => {
  const [expanded, toggleExpanded] = useToggle(initialExpanded)

  return (
    <Collapse
      defaultActiveKey={initialExpanded ? ['1'] : undefined}
      ghost
      expandIcon={() => null}
      onChange={toggleExpanded}
    >
      <Panel
        header={
          <HeaderWrapper>
            {title}
            {expanded ? (
              <UpOutlined style={{ fontSize: ICON_SIZE }} />
            ) : (
              <DownOutlined style={{ fontSize: ICON_SIZE }} />
            )}
          </HeaderWrapper>
        }
        key="1"
      >
        {children}
      </Panel>
    </Collapse>
  )
}

export default MenuAccordion
