import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: inline-block;
  background-color: #f2f6fa;
  border-radius: 24px;
  padding: 24px;
`

export const BottomWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`

export const EtnTitle = styled.h2`
  font-family: Gilroy;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: black;
`

export const EtnSubTitle = styled.h3`
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  color: black;
  text-transform: uppercase;
  padding-top: 3px;
`
