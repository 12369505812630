import * as React from 'react'
import { FC } from 'react'
import { Skeleton } from 'antd'
import { noop } from 'lodash'
import { useGetUserQuery } from '@services/users'
import Title from '@organisms/Title'
import AccordionBodyField from '@organisms/AccordionBodyField'
import DisplayPhone from '@organisms/DisplayPhone'
import DisplayEmail from '@organisms/DisplayEmail'
import { Tab } from '@organisms/Tabs'
import CURATOR_STATUS_OPTIONS from '@const/curatorStatuses'
import { StyledTitle } from '../styled'
import {
  CuratorNameWrapper,
  CuratorStatusWrapper,
  StyledContentBlock,
} from './styled'

const LABEL_WIDTH = 100

type Props = {
  title: string
  id?: number
}

const CuratorField: FC<Props> = ({ title, id }) => {
  const { data, isLoading } = useGetUserQuery(id!, {
    skip: !id,
  })
  const { fullName, phone, email, curatorStatus } = data || {}

  const {
    label = CURATOR_STATUS_OPTIONS[0].label,
    badgeColor = CURATOR_STATUS_OPTIONS[0].badgeColor,
  } = CURATOR_STATUS_OPTIONS.find((item) => item.value === curatorStatus) || {}

  if (isLoading) {
    return (
      <Skeleton.Button
        active
        style={{
          height: 230,
          borderRadius: 25,
          marginBottom: 20,
          width: 550,
        }}
      />
    )
  }

  return (
    <StyledContentBlock>
      <Title mySize="h3">{title}</Title>
      <CuratorNameWrapper>
        <StyledTitle>{fullName}</StyledTitle>
        <CuratorStatusWrapper>
          <Tab
            id="id"
            isActive={false}
            onClick={noop}
            tabSize="small"
            children={null}
            title={label}
            badgeColor={badgeColor}
            withoutHover
          />
        </CuratorStatusWrapper>
      </CuratorNameWrapper>
      <AccordionBodyField
        labelWidth={LABEL_WIDTH}
        label="Телефон"
        value={phone ? <DisplayPhone phone={phone} /> : null}
      />
      <AccordionBodyField
        labelWidth={LABEL_WIDTH}
        label="EMAIL"
        value={email ? <DisplayEmail email={email} /> : null}
      />
    </StyledContentBlock>
  )
}

export default CuratorField
