import { compact } from 'lodash'
import { isMobile } from '@const/device'
import { Routes } from '@enums/Routes'
import { LinkItemProps } from '@templates/LinkItem'

export const FOOTER_LINKS_CONFIG: LinkItemProps[] = compact([
  {
    text: 'Инструкции',
    route: Routes.GUIDES,
  },
  {
    text: 'Школа экспертов',
    isSiteLink: true,
  },
  {
    text: 'Политика конфиденциальности',
    route: Routes.PRIVACY_POLICY,
  },
  isMobile && {
    text: 'Мой профиль',
    route: Routes.PROFILE,
  },
  isMobile && {
    text: 'Выход',
    isLogout: true,
  },
])
