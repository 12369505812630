import { FC, ReactNode, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { useToggle } from 'react-use'
import { ReactComponent as Close } from '@interface-images/close-black.svg'
import { ReactComponent as WhiteClose } from '@interface-images/close-white.svg'
import { useAppSelector } from '@hooks/useAppSelector'
import { CloseWrapper, ContentWrapper, ModalContent, Wrapper } from './styled'

type Props = {
  children: ReactNode
  onModalClose?: () => void
  onCloseClick?: () => void
  visible: boolean
  width?: string
  withoutWhiteBackground?: boolean
  withoutClose?: boolean
}

const Modal: FC<Props> = ({
  children,
  onModalClose,
  onCloseClick,
  visible: initialVisible,
  width,
  withoutWhiteBackground = false,
  withoutClose = false,
}) => {
  const onboardingStarted = useAppSelector((state) => state.onboarding.started)
  const [visible, toggleVisible] = useToggle(false)

  const handleCloseModal = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
    onModalClose?.()
    onCloseClick?.()
    toggleVisible(false)
  }

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === 'Escape') {
      toggleVisible(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  useEffect(() => {
    toggleVisible(initialVisible)
  }, [initialVisible])

  if (!visible) return null

  return (
    <Wrapper onClick={(event) => event.stopPropagation()}>
      <OutsideClickHandler
        onOutsideClick={(e) => {
          if (onboardingStarted) return
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const classNames = e.target.className
          if (
            classNames?.includes?.('popover') ||
            classNames?.includes?.('ant-picker') ||
            withoutClose
          ) {
            return
          }

          handleCloseModal(e)
        }}
      >
        <ModalContent
          withoutWhiteBackground={withoutWhiteBackground}
          width={width}
          onClick={(event) => event.stopPropagation()}
          id="modal"
        >
          {!withoutClose && (
            <CloseWrapper onClick={handleCloseModal}>
              {withoutWhiteBackground ? <WhiteClose /> : <Close />}
            </CloseWrapper>
          )}
          <ContentWrapper onKeyDown={handleKeyPress}>{children}</ContentWrapper>
        </ModalContent>
      </OutsideClickHandler>
    </Wrapper>
  )
}

export default Modal
