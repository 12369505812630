import {
  Dispatch,
  FC,
  forwardRef,
  memo,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { isEmpty } from 'lodash'
import Select, { DropdownIndicatorProps, SelectInstance } from 'react-select'
import { useDebounce, useMount, useToggle } from 'react-use'
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'
import { setSearchValue } from '@utils/setSearchValue'
import { memoEqual } from '@utils/memo'
import SingleValue from './SingleValue'
import Control from './Control'
import ValueContainer from './ValueContainer'
import OptionComponent from './Option'
import DropdownIndicator from './DropdownIndicator'
import ClearIndicator from './ClearIndicator'
import IndicatorSeparator from './IndicatorSeparator'
import Menu from './Menu'
import Placeholder from './Placeholder'
import MultiValue from './MultiValue'

export type Option<T = any> = {
  value: T
  label: string
  badgeColor?: string
  withManualEntryText?: boolean
}

type Props = {
  options: Option[]
  length?: number
  setPage?: Dispatch<SetStateAction<number>>
  setFilters?: Dispatch<SetStateAction<any>>
  value?: Option
  placeholder?: string
  isMulti?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  onChange?: (newValue: any) => void
  fieldName?: string
  fieldNameForSubmit?: string
  DropdownIndicator?: FC<DropdownIndicatorProps>
  hideSeparator?: boolean
  hasError?: boolean
  dropdownWidth?: number
  openMenuOnClick?: boolean
  menuIsOpen?: boolean
  withoutFillSelected?: boolean
  menuPosition?: 'absolute' | 'fixed'
  withoutFetchOptions?: boolean
  prepareSearch?: (value: string) => string
  ControlComponent?: FC
  withManualEntryText?: boolean
  selectedMultilineValues?: string[]
} & Omit<StateManagerProps, 'onChange'>

const NewSelect = forwardRef<SelectInstance, Props>((props, outerRef) => {
  const {
    options = [],
    value,
    placeholder,
    isMulti,
    setFilters,
    setPage,
    DropdownIndicator: DropdownIndicatorProps,
    hideSeparator,
    length = 0,
    isSearchable = true,
    isClearable = true,
    menuPosition,
    withoutFetchOptions = false,
    prepareSearch,
    withManualEntryText,
    onBlur,
    isDisabled,
    selectedMultilineValues = [],
    isLoading,
  } = props

  const innerRef = useRef<SelectInstance | null>(null)
  useImperativeHandle(outerRef, () => innerRef.current!, [])

  const [inputValue, setInputValue] = useState('')
  const [isSearched, toggleIsSearched] = useToggle(false)
  const [fromPagination, toggleFromPagination] = useToggle(false)
  const [optionsState, setOptionsState] = useState([] as Option[])

  useEffect(() => {
    if (!withoutFetchOptions && options) {
      if (isSearched) {
        setOptionsState(options)
      } else if (fromPagination) {
        setOptionsState((prev) => [...prev, ...options])
        toggleFromPagination(false)
      } else {
        setOptionsState(options)
      }
    }
  }, [options])

  useMount(() => {
    if (withoutFetchOptions) {
      setOptionsState(options)
    }
  })

  useEffect(() => {
    if (length === 0 && options.length === 0) {
      setOptionsState([])
      setPage?.(1)
    }
  }, [length])

  const handleSearch = (value: string) => {
    toggleIsSearched(true)
    setPage?.(1)
    setFilters?.(setSearchValue(prepareSearch ? prepareSearch(value) : value))
  }

  const setManualValue = () => {
    if (
      withManualEntryText &&
      inputValue &&
      optionsState.every(
        ({ label }) => !label.toLowerCase().includes(inputValue.toLowerCase())
      )
    ) {
      props.onChange?.({
        label: inputValue,
        value: inputValue,
        withManualEntryText: true,
      })

      innerRef.current?.blur()
    }
  }

  useDebounce(
    () => {
      handleSearch(inputValue)
    },
    300,
    [inputValue]
  )

  return (
    <>
      {window.ENABLE_SELECT_VALUE && (
        <span style={{ fontSize: 10 }}>{value?.value}</span>
      )}
      <Select
        {...props}
        isLoading={isLoading}
        ref={(ref) => {
          innerRef.current = ref as unknown as SelectInstance
        }}
        menuPosition={menuPosition}
        filterOption={({ value }) => !selectedMultilineValues.includes(value)}
        options={isLoading ? undefined : optionsState}
        value={isEmpty(value) ? null : value} // temp
        placeholder={
          Array.isArray(value) && value?.length > 0
            ? `Выбрано | ${value?.length}`
            : placeholder || 'Выбрать из списка'
        }
        hideSelectedOptions={!isMulti}
        closeMenuOnSelect={!isMulti}
        controlShouldRenderValue={!isMulti}
        isMulti={isMulti}
        components={{
          ValueContainer,
          Option: OptionComponent,
          Control,
          DropdownIndicator: DropdownIndicatorProps || DropdownIndicator,
          ClearIndicator,
          IndicatorSeparator: hideSeparator ? () => <></> : IndicatorSeparator,
          Menu,
          SingleValue,
          Placeholder,
          MultiValue,
        }}
        menuPlacement="auto"
        isSearchable={isSearchable}
        isDisabled={isDisabled}
        isClearable={isClearable}
        loadingMessage={() => 'Идет загрузка'}
        noOptionsMessage={() =>
          withManualEntryText
            ? 'Нажмите Enter, чтобы добавить своё значение'
            : 'Нет элементов'
        }
        onInputChange={(text) => {
          setInputValue(text)
        }}
        onMenuScrollToBottom={() => {
          toggleIsSearched(false)
          setPage?.((prev) => prev + 1)
          toggleFromPagination(true)
        }}
        styles={{
          input: (baseStyles, { selectProps }) => ({
            ...baseStyles,
            color: !isEmpty(selectProps.value) ? 'white' : 'black',
          }),
        }}
        onBlur={(event) => {
          onBlur?.(event)
          setManualValue()
        }}
        onKeyDown={(event) => {
          if (event.key?.toLowerCase() !== 'enter') {
            return
          }
          event.preventDefault()
          setManualValue()
        }}
      />
    </>
  )
})

export type { Props as SelectProps }
export default memo(NewSelect, memoEqual)
