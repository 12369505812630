import styled from '@emotion/styled'

export const HeaderWrapper = styled.div`
  text-transform: uppercase;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #7b7b7b;
  display: flex;
  gap: 10px;
  align-items: center;
`
