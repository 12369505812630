import { MAX_LENGTH_RULE, REQUIRED_VALIDATION_RULE } from '@const/validation'
import Checkbox, { CheckboxProps } from '@organisms/Checkbox'
import { FieldForRender, FieldParams } from '..'

const checkboxField = (params: FieldParams): FieldForRender => {
  const { label, isRequired, componentProps: props } = params || {}

  const componentProps: CheckboxProps = {
    ...props,
    marginLeft: 8,
  }

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    rules: {
      ...MAX_LENGTH_RULE,
      ...(isRequired ? REQUIRED_VALIDATION_RULE : {}),
    },
    Component: Checkbox,
    componentProps,
  }
}

export default checkboxField
