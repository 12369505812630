import { FC } from 'react'
import { ReactComponent as Logo } from '@interface-images/main-logo.svg'
import { ReactComponent as Burger } from '@main-menu/burger.svg'
import { isMobile } from '@const/device'
import useMe from '@hooks/useMe'
import { useGetBlobFileQuery } from '@services/files'
import Bell from '../../Header/Bell'
import { BellWrapper, BurgerWrapper, MainLogoWrapper, Wrapper } from './styled'

type Props = {
  handleBurgerClick: () => void
}

const MainLogo: FC<Props> = ({ handleBurgerClick }) => {
  const { company } = useMe()

  const { data: logoUrl } = useGetBlobFileQuery(
    company?.logo?.filePath as string,
    {
      skip: !company?.logo?.filePath,
    }
  )

  return (
    <Wrapper>
      <BurgerWrapper>
        <Burger onClick={handleBurgerClick} />
      </BurgerWrapper>
      <MainLogoWrapper>
        {logoUrl ? <img src={logoUrl} width={isMobile ? 25 : 50} /> : <Logo />}
        {isMobile && (
          <BellWrapper>
            <Bell />
          </BellWrapper>
        )}
      </MainLogoWrapper>
    </Wrapper>
  )
}

export default MainLogo
