import styled from '@emotion/styled'
import { isMobile } from '@const/device'
import Title from '@organisms/Title'

export const StyledTitle = styled(Title)`
  margin: 10px 0 20px 10px;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: ${() => (isMobile ? 'column' : 'row')};
  max-width: 1350px;
  flex-wrap: wrap;
`
