import styled from '@emotion/styled'

export const StyledTitle = styled.div<{
  hovered: boolean
}>`
  color: ${({ hovered, theme }) => (hovered ? theme.colors.main : 'black')};
  font-weight: 500;
  font-size: ${({ theme }) => theme.text.pNormal.fontSize};
  line-height: ${({ theme }) => theme.text.pNormal.lineHeight};
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 97%;
  align-items: center;
  max-height: ${({ theme }) => theme.sizes.accordionHeight};
`

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
`
