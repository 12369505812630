import { FC } from 'react'
import { useToggle } from 'react-use'
import { UseFormReturn } from 'react-hook-form'
import { FilterState } from '@hooks/useItemsFilters'
import Modal from '@organisms/Modal'
import SecondaryButton from '@organisms/SecondaryButton'
import { PieChartOutlined } from '@ant-design/icons'
import { Fields } from '@templates/AddNewItemModal/Content'
import Onboarding, { OnboardingProps } from '@templates/Onboarding'
import { CardsContainer, StyledTitle } from './styled'
import ReportCard from './ReportCard'

export type Report = {
  title: string
  onClick: (params: FilterState) => void
  isLoading: boolean
  id?: string
}

export type ReportsProp = {
  items: Report[]
  filterModalFields: Fields
  methods: UseFormReturn
  transformFormValues?: (form: any) => any
  onSelectReport?: (index: number) => void
}

type Props = {
  reports: ReportsProp
  reportsOnboarding?: OnboardingProps
}

const ReportsButton: FC<Props> = ({
  reports: {
    items,
    filterModalFields,
    methods,
    transformFormValues,
    onSelectReport,
  },
  reportsOnboarding,
}) => {
  const [visible, toggleVisible] = useToggle(false)

  return (
    <>
      <SecondaryButton
        onClick={toggleVisible}
        leftIcon={<PieChartOutlined />}
        id="reports"
      >
        Отчёты
      </SecondaryButton>
      <Modal visible={visible}>
        {reportsOnboarding && visible && <Onboarding {...reportsOnboarding} />}
        <StyledTitle>Выберите отчёт</StyledTitle>
        <CardsContainer id="reportsContainer">
          {items.map(({ title, isLoading, onClick, id }, index) => (
            <ReportCard
              transformFormValues={transformFormValues}
              methods={methods}
              filterModalFields={filterModalFields}
              onSubmitFilter={onClick}
              title={title}
              isLoading={isLoading}
              onSelectReport={() => onSelectReport?.(index)}
              id={id}
              key={title}
            />
          ))}
        </CardsContainer>
      </Modal>
    </>
  )
}

export default ReportsButton
