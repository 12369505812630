import * as React from 'react'
import { FC, useEffect } from 'react'
import { FieldValues, FormProvider } from 'react-hook-form'
import { SubmitHandler } from 'react-hook-form/dist/types/form'
import useFormProcessor from '@hooks/useFormProcessor'
import {
  useGetPhoneCallMutation,
  usePostValidationCodeMutation,
} from '@services/auth/api'
import Link, { LinkStyle } from '@organisms/Link'
import BackButton from '@organisms/BackButton'
import Field from '@templates/Field'
import { maskedField } from '@templates/AddNewItemModal/templates'
import { StyledSubtitle } from '../../../styled'
import { BackButtonContainer, LinkWrapper, Subtitle } from './styled'

const CODE_FIELD_NAME = 'code'

type Props = {
  handleBack: () => void
  submittedNumber?: string
}

const CodeForm: FC<Props> = ({ submittedNumber, handleBack }) => {
  const [postValidationCode, { error, isLoading }] =
    usePostValidationCodeMutation()

  const [getPhoneCall, { error: repeatError, isLoading: repeatIsLoading }] =
    useGetPhoneCallMutation()

  const methods = useFormProcessor({ apiErrors: error || repeatError })
  const { clearErrors, handleSubmit, watch } = methods

  const handleRepeatCall = () => {
    if (submittedNumber) {
      getPhoneCall(submittedNumber)
    }
  }

  const onSubmit: SubmitHandler<FieldValues> = (form) => {
    if (submittedNumber) {
      postValidationCode({
        phone: submittedNumber,
        code: form[CODE_FIELD_NAME],
      })
    }
  }

  const code = watch(CODE_FIELD_NAME)

  const codeField = maskedField({
    isRequired: true,
    label: 'Последние 4 цифры',
    mask: '0000',
    placeholder: '____',
    name: CODE_FIELD_NAME,
    isLoading: isLoading || repeatIsLoading,
    widthMultiplier: 1.2,
  })

  useEffect(() => {
    if (code?.length === 4) {
      clearErrors()
      handleSubmit(onSubmit)()
    }
  }, [code])

  if (!submittedNumber) return null

  return (
    <FormProvider {...methods}>
      <BackButtonContainer>
        <BackButton onClick={handleBack} type="button" />
      </BackButtonContainer>
      <StyledSubtitle>Введите 4 последних цифры</StyledSubtitle>
      <StyledSubtitle isLast>
        номера, с которого мы сейчас позвоним{' '}
      </StyledSubtitle>
      <Subtitle>Звоним на ваш номер {submittedNumber}</Subtitle>
      <Field {...codeField} />
      <LinkWrapper>
        <Link
          text="Позвонить ещё раз"
          linkStyle={LinkStyle.DEFAULT}
          onClick={handleRepeatCall}
        />
      </LinkWrapper>
    </FormProvider>
  )
}

export default CodeForm
