export const getProtectionMeanTypeName = (index: number) =>
  `protectionMeans.${index}.type`
export const getConstructionName = (index: number) =>
  `protectionMeans.${index}.constructionMean`
export const getCountName = (index: number) =>
  `protectionMeans.${index}.quantity`
export const getPeriodName = (index: number) =>
  `protectionMeans.${index}.updateFrequency`
export const getUpdateBeforeName = (index: number) =>
  `protectionMeans.${index}.updateBeforeAt`
export const getIsChildName = (index: number) =>
  `protectionMeans.${index}.isChild`
export const getHasEqualConstructionsName = (index: number) =>
  `protectionMeans.${index}.hasEqualConstructions`
