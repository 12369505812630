import { MAX_LENGTH_RULE, REQUIRED_VALIDATION_RULE } from '@const/validation'
import Input, { InputProps } from '@organisms/Input'
import { FieldForRender, FieldParams } from '..'

type Params = {
  placeholder: string
  isLoading?: boolean
  big?: boolean
  componentProps?: InputProps
} & FieldParams

const textField = (params: Params): FieldForRender => {
  const {
    label,
    placeholder,
    isRequired,
    isLoading = false,
    widthMultiplier = 1,
    componentProps: props,
    big = false,
    name,
  } = params

  const componentProps: InputProps = {
    ...props,
    placeholder,
    big,
    isLoading,
  }

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    rules: {
      ...MAX_LENGTH_RULE,
      ...(isRequired ? REQUIRED_VALIDATION_RULE : {}),
    },
    Component: Input,
    componentProps,
    widthMultiplier,
    emptyValue: '',
    id: name,
  }
}

export default textField
